html {
	overflow-x: hidden;
}

body {
	&.layout-boxed-lg {
		#page {
			max-width: $container-lg + 20px;
			@include box-shadow(0 0 5px lighten($gray, 50%));
			margin: 0 auto;
			background: $white;
		}
	}
	&[class*="page-"] #content {
		padding: 20px 15px;
		img {
			max-width: 100%;
		}
	}
	&.common-home #content {
		padding: 0;
	}
	&[class*="page-category"] {
		#content {
			padding: 0;
			h1 {
				margin-top: 0;
			}
		}
	}
	&[class*="-account"] #content {
		h1, h2 {
			font-size: $font-size-base + 5;
		}
	}

	&[class*="page-product"] #content {
		overflow: visible;
		padding: 0;
		.owl-carousel .owl-wrapper-outer {
			overflow: visible;
		}
		.owl-carousel {
			margin-bottom: 35px;
		}
	}
}

#sys-notification {
	position: fixed;
	top: 10px;
	width: 100%;
	z-index: 9999;
	right: 0;
}

/*
* HEADER 
*/

#topbar {
	background: $topbar-outside-bg;
	font-size: $topbar-font-size;
	color: $topbar-color;
	height: 40px;
	line-height: 25px;
	padding: 8px 0;
	.topbar-left {
		position: relative;
	}
	.custom-top {
		&:nth-child(1) {
			margin-right: 20px;
		}
		i {
			padding-right: 5px;
		}
	}
	.container {
		.inside {
			background: $topbar-bg;
			padding: $topbar-container-padding;
		}
	}

}

.headercart {
	flex: 0 1 330px;
	display: block;
	text-align: right;
	& > div {
		float: right;
	}
}

.header-bottom {
	background: $wh;
	& > .container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 120px;
		&:before, &:after {
			content: none;
		}
	}
	& > .holiday_works {
		@media (min-width: 992px) {
			display: none;
		}
	}
	#topcontacts {
		flex: 0 1 330px;
		display: flex;
	}
	.topcontacts {
		align-self: center;
		align-items: center;
		display: flex;
		position: relative;
		font-size: 13px;
		margin: 5px 0;
		.media-title {
			display: flex;
			&  > svg {
				padding: 2px;
				width: 40px;
				height: 40px;
			}
			a > svg {
				padding: 2px;
				width: 40px;
				height: 40px;
			}
		}
		.media-body {
			max-width: 190px;
			margin-left: 10px;
			span {
				white-space: nowrap;
				margin-right: 10px;
				display: inline-block;
				a {
					color: $bk !important;
				}
			}
		}
	}
	.logo-container {
		flex: 1 0 auto;
		.logo-store {
			text-align: center;
		}
		img {
			max-width: 260px;
		}
	}
	.main-menu {
		flex: 1 0 auto;
		align-self: center;
	}
	.nav-search {
		flex: 0 1 200px;
		align-self: center;
	}
}
@media (min-width: 1200px) {
	#topcontacts .worktime {
		display: none;
	}
	.headercart {
		flex: 0 1 220px;
	}
	.topcontacts {
		&.phone {
			.media-body {
				font-size: 24px;
				line-height: 28px;
				font-weight: 600;
				letter-spacing: 0.02em;
			}
		}
		&.worktime {
			.media-body {
				font-size: 16px;
				line-height: 20px;
				font-weight: 600;
				letter-spacing: 0.01em;
				span:last-child {
					letter-spacing: .019em;
				}
			}
		}
	}
	.header-bottom #topcontacts {
		flex: 0 1 260px;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	#topcontacts .worktime {
		display: none;
	}
	.topcontacts {
		&.phone {
			.media-body {
				font-size: 18px;
				line-height: 22px;
				font-weight: 600;
				letter-spacing: 0.02em;
			}
		}
		&.worktime {
			.media-body {
				font-size: 16px;
				line-height: 20px;
				font-weight: 600;
				letter-spacing: 0.01em;
				span:last-child {
					letter-spacing: .019em;
				}
			}
		}
	}
	.header-bottom #topcontacts {
		flex: 0 1 215px;
	}
	.headercart {
		flex: 0 1 220px;
	}
}
@media (min-width: 992px) {
	#cart .wrap-cart {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		.icon-cart {
			width: 100%;
		}
	}
}
@media (max-width: 991px) {
	.header-bottom .worktime.desktop {
		display: none;
	}
}
.header-menu {
	background-color: $tc;
	& > .container {
		display: flex;
		justify-content: space-between;
		&:before, &:after {
			content: none;
		}
	}
}

.shopping-cart-table {
	background: $white;
	tbody {
		.column-image {
			position: relative;
			img {
				border: none;
			}
			.product-label {
				top: 5px;
				left: 5px;
				padding: 0 5px;
				background: $tc;
				span {
					color: $wh;
				}
			}
		}
	}
	& > thead:first-child > tr:first-child > td {
		border-top: 1px solid $lbc;
	}
	&.table {
		tr td {
			border: 1px $lbc solid;
		}
	}
	.btn {
		font-size: 23px;
		padding: 0 7px;
		i {
			vertical-align: 2px;
		}
	}
}

.tp-bullets .counter {
	display: none !important;
}

.search-focus {
	color: $search-icon-color;
	cursor: pointer;
	border-right: 1px solid $border-color;
	padding-right: 15px;
	line-height: 32px;
	height: 27px;
}

.nav-search {
	&.open {
		height: 44px;
		top: 0;
		visibility: visible;
		@include opacity(1);
	}
}

/* multi logo */
.logo-store {
	position: relative;
	a {
		vertical-align: middle;
	}
}

#logo-theme {
	a {
		background: url(#{$image-theme-path}logo.png) no-repeat;
		display: inline-block;
		@include size(170px, 37px);
		span {
			display: none;
		}
	}
}

/*
* CONTENT
 */
#pav-masshead {
	.inner {
		@include container-layout-variant($masshead-color, $masshead-bg, $masshead-color, lighten($masshead-color, 5%));
	}
}

.breadcrumbs {
	.container {
		background: transparent;
	}
}

/*
* NAVIGATION
*/
#pav-mainnav {
	.inner {
		background-color: $theme-color-secondary;
	}
}

#breadcrumb {
	padding-left: 0;
	padding-right: 0;
}

#footer {
	ul, ol {
		margin: 0;
		li {
			line-height: 20px;
			a {
				font-size: $font-size-base - 2;
				color: $footer-color;
				text-transform: uppercase;
				font-weight: 500;
				@include transition(all 0.4s ease 0s);
				&:hover {
					color: $white;
				}
			}
		}
	}
}

.footer-top {
	background: transparent;
	margin-bottom: -97px;
}

.footer-center {
	background: $ddg;
	padding: 30px 0;
	color: $white;
	.box {
		.box-heading {
			font-size: 20px;
			text-transform: uppercase;
			font-weight: 600;
			margin-bottom: 30px;
		}
	}
	.panel {
		background: transparent;
		margin-bottom: 0;
		.panel-heading {
			padding: 0;
		}
		.panel-body {
			padding: 20px 0 0;
		}
		.panel-title {
			color: $white;
		}
	}
	.footer-name {
		text-align: center;
		font-size: 12px;
	}
	.main-footer {
		display: flex;
		justify-content: space-between;
	}
}

.footer-bottom {
	background: $blue;
	.inside {
		background: url("#{$image-theme-path}pattern.jpg") repeat 0 0;
		position: relative;
		@include rounded-corners(3px);
		@include element-absolute($white);
		.bg-blue {
			background: url("#{$image-theme-path}pattern2.jpg") repeat 0 0;
		}
	}
}

/* POWER BY */
#powered {
	background: $bk;
	a {
		font-size: $font-size-base - 2;
		color: $footer-color;
		font-weight: 500;
		text-transform: uppercase;
		&:hover {
			color: $white;
		}
	}
	.copyright {
		padding: 5px 0 0 15px;
		color: $white;
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
	}
	ul.links {
		line-height: 16px;
		padding-left: 20px;
		li {
			padding: 0 10px;
		}
	}
	.inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
	.oferta p {
		font-size: 12px;
		color: $wh;
	}
	.payment-img {
		img {
			max-height: 61px;

			&:last-child {
				display: none;
				margin: 8px 0;
			}
		}
	}
}
.for-send-error-press {
	margin: 15px 0;
	font-style: italic;
}