.breadcrumb {
	> li {
		display: inline-block;
		&:first-child {
			a span {
				font-size: 16px;
				line-height: 1;
			}
		}
		+ li:before {
			content: "#{$breadcrumb-separator}\00a0"; // Unicode space added since inline-block means non-collapsing white-space
			padding: 0 5px;
			color: $text-color;
		}
	}
	> .active {
		font-weight: 600;
	}
}