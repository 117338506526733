.owl-carousel {
	display: block !important;
}

.category-info {
	padding: 0;
	.image {
		padding: 0;
		margin-bottom: 19px;
	}
}

.filter-right {
	& > div {
		display: flex;
		align-items: center;
	}
	.sort {
		margin-left: 15px;
	}
	label {
		line-height: 14px;
	}
}

.refine-search {
	margin-top: 15px;
	ul, ol {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;
		li {
			padding: 10px 0;
			margin: 0 10px;
			line-height: normal;
			position: relative;
			width: calc(25% - 20px);
			a {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				color: $bk;
				align-items: center;
				background: no-repeat transparent top center;
				background-size: 90%;
				padding-top: 150px;
				box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
				&:hover {
					text-decoration: none;
					span {
						color: $wh;
						background: rgba(30, 72, 123, 0.7);
					}
				}
				span {
					width: 100%;
					padding: 10px 5px;
					text-align: center;
					font-weight: 600;
					color: $tc;
					background: rgba(255,255,255,.7);
					line-height: 17px;
					display: flex;
					align-items: center;
					justify-content: center;
					@include transition (all 200ms ease-in-out);
					figure {
						text-align: center;
					}
				}
			}
			@media (max-width: 767px) {
				width: calc((100% / 3) - 20px);
			}
			@media (max-width: 500px) {
				width: calc((100% / 2) - 20px);
			}
			@media (max-width: 400px) {
				font-size: 12px;
			}
		}
	}
	h3 {
		color: #474747;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
	}
}

.col-md-12 {
	.refine-search ul li, .refine-search ol li {
		width: calc(20% - 20px);
	}
}

.style-panel {
	.panel {
		margin: 15px 0;
		border: 1px solid #ddd;
	}
	.panel-collapse {

	}
	.panel-heading {

	}
}

.sidebar {
	.products-block {
		border: $brand-border;
		@include rounded-corners(3px);
	}
	.product-label {
		display: none;
	}
	.product-col {
		width: 100%;
		clear: both;

	}
	.products-row {
		&:last-child {
			.product-block {
				border-bottom: none;
			}
		}
	}
	.price .price-old {
		font-size: $font-size-base - 1;
	}
	.price .price-new {
		font-size: $font-size-base;
	}
	.product-block {
		overflow: hidden;
		padding: 15px;
		border-bottom: 1px solid $border-color;
		.image {
			padding: 0;
		}
		.block-img {
			float: left;
			margin-right: 10px;

		}
		.top {
			padding: 0;
		}
		.product-meta {
			overflow: hidden;
			text-align: left;
			padding: 0;
		}
		.name {
			padding-top: 0;
			margin-bottom: 0;
		}
	}
	.bottom {
		display: none;
	}
	.product-grid {
		.product-block {
			padding: 15px;
			&:hover {
				.cart, .top {
					margin: 0;
				}
				.image {
					@include opacity(1);
				}
			}
		}
	}

}

.product-info {
	.product-meta {
		.btn-sm, .btn-group-sm > .btn {
			padding: 0 15px;
		}
	}
}

.subcategories {
	margin-bottom: -1px;
	background-color: $product-filter-bg;
}

/* 
 *  LISTING PRODUCTS PAGE 
 */
.product-filter {
	color: $product-filter-color;
	text-transform: uppercase;
	font-size: $product-filter-font-size;
	font-weight: 500;
	margin: 10px 0;
	span {
		margin-right: 5px;
	}
	.filter-right > div {
		padding-top: 3px;
		padding-left: 5px;
	}

	.display {
		float: left;
	}
	.form-control {
		display: inline-block;
		padding: 0 20px;
		margin: 0 5px;
		@include size(auto, 36px);
		text-transform: capitalize;
	}
	.btn-switch {
		display: inline-block;
		margin-right: 5px;
		color: #969696;
		background: transparent;
		padding: 0;
		line-height: 35px;
		font-weight: 500;
		font-size: 34px;
		@include transition (all 200ms ease 0s);
		i {
			padding: 0 5px;
		}
		&:hover, &.active {
			color: $theme-color;
			@include box-shadow (none);
		}
		&#grid-switch {
			font-size: 32px;
		}
	}
}

.product-list {
	.description {
		display: block;
	}
	.product-meta {
		padding-left: 0;
	}
	.product-block {
		display: flex;
		border: 1px solid #eee;
		margin: 10px 0;
		border-radius: 0;
		.name {
			font-weight: 600;
			font-size: 18px;
			height: auto !important;
			a {
				span {
					display: inline-block;
				}
			}
		}
		.price {
			margin-bottom: 6px;
			text-align: left;
			.price-new {
				font-size: 26px;
			}
		}
		.block-img {
			width: 30%;
		}
		.bottom {
			display: flex;
			padding: 0 20px;
			.cart {
				button {
					padding: 0 30px;
				}
				display: inline-block;
			}
			.quickview {
				a {
					left: -1px;
					position: relative;
				}
			}
		}
	}
}

#products {
	.products-block {
		margin: 0 -17px;
	}
}

.product-grid, .products-owl-carousel {
	&.product-related {
		.products-block {
			margin: 5px;
		}
		.product-block {
			width: calc(100% / 4 - 13px);
		}
	}
	.product-block {
		display: inline-block;
		margin: 15px;
		padding-bottom: 15px;
		width: calc(100% / 3 - 33px);
		border: 1px solid $lg;
		@include rounded-corners(0);
		@include transition(border 0.2s ease-in-out);
		.cart {
			padding: 0;
			flex: 1 1 auto;
			.btn {
				width: 100%;
			}
		}
		.compare {
			flex: 0 0 36px;
			i {
				font-size: 22px;
				vertical-align: -2px;
			}
		}
		.quickview {
			flex: 0 0 36px;
			margin-left: -1px;
			i {
				font-size: 22px;
				vertical-align: -2px;
			}
		}
		.compare, .quickview {
			a, button {
				width: 100%;
			}
		}
		.bottom {
			@include transition (all 400ms ease 0s);
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 999;
			background-color: $white;
			text-align: center;
			display: flex;
			padding: 0 20px;
		}
		&:hover {
			border-color: $bc;
		}
	}
}

.products-owl-carousel {
	.owl-stage-outer {
		padding: 15px 0;
	}
	.product-block {
		width: auto;
	}
}

/* 
* PRODUCT DEALS
*/
.productdeals {
	.widget-inner {
		.carousel {
			position: static;
		}
	}
}

/* 
* PRODUCT INFORMATION PAGE
*/
.product-info {
	.box-product-infomation {
		margin-top: 20px;
	}
	.title-product {
		font-size: 30px;
		margin-top: 0;
		font-weight: 600;
	}
	.image-additional {
		overflow: hidden;
		.owl-carousel {
			margin-bottom: 0 !important;
		}
	}
	.image-additional .item {
		border: 1px solid $border-color;
		text-align: center;
		margin: 0 2px;
		background-color: $white;
		position: relative;
		a.active {
			&:before {
				position: absolute;
				width: 100%;
				height: 2px;
				background-color: $theme-color;
				content: "";
				left: 0;
				z-index: 1;
				top: -1px;
			}
			&:after {
				position: absolute;
				left: 50%;
				margin-left: -8px;
				border: 6px solid transparent;
				border-bottom: 6px solid $theme-color;
				content: "";
				z-index: 1;
				top: -12px;
			}
		}
	}
	.thumbs-preview {
		margin-top: 20px;
		a + a {
			margin-left: 10px;
		}
	}
	.vertical {
		&.image-additional {
			.carousel-control {
				display: none;
			}
		}
		&:hover {
			.carousel-control {
				display: block;
			}
		}

		.center {
			left: 0;
			right: 0;
			margin: 0 auto;
		}

		.top {
			top: 0;
			bottom: auto;
		}

		.bottom {
			top: auto;
			bottom: 0;
		}
	}
	.horical {
		display: block;
		width: 100%;
		text-align: center;
		position: relative;

		&:hover {
			.carousel-control {
				display: block;
			}
		}
	}
	.check-box {
		padding-right: 5px;
		font-size: $font-size-base - 4;
	}
	.image {
		position: relative;
		text-align: center;
		overflow: hidden;
		border: 1px solid $border-color;
	}
	.share {
		@include clearfix;
		padding: 20px 0;
		display: none;
	}
	.wrap-qty {
		border: 1px solid $border-color;
		height: 32px;
		background-color: $white;
		padding: 3px;
		> div {
			display: table-cell;
			float: left;
		}
	}
	.quantity {
		padding-bottom: 15px;
	}
	.quantity-adder {
		display: block;
		position: relative;
		background-color: $white;
		@include border-radius($border-color, 0);
		& + .btn-sm {
			padding: 0 18px;
		}
		.add-action {
			@include size(40px, 35px);
			color: $black;
			display: block;
			position: relative;
			cursor: pointer;
			font-size: 8px;
			line-height: 39px;
			text-align: center;
			&:hover, &:focus {
				color: $theme-color;
			}
		}
		.quantity-number {
			input {
				@include size(40px, 35px);
				text-align: center;
				@include inline-block;
				@include rounded-corners(0);
				box-shadow: none;
				padding: 0;
				border: none;
				color: $black;
				font-size: 16px;
			}
		}
		.minimum {
			line-height: 35px;
			font-size: 11px;
			margin-left: 10px;
		}
	}
	.product-meta {
		a {
			font-weight: 500;
			a.btn-sm {
				padding: 0 19px;
			}
			&.btn-primary {
				margin-left: 16px;
				font-size: 20px;
				line-height: 33px;
				padding: 0 10px;
			}
		}
		.cart {
			padding-bottom: 0;
			font-size: 0;
		}
		.btn-compare {
			color: $bk;
			font-size: 24px;
			padding: 2px 18px;
			height: 41px;
			&:hover {
				color: $tc;
			}
		}
	}
	.advantages_block {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-top: 10px;
		.advantage_item {
			width: 50%;
			min-width: 262px;
			display: flex;
			align-items: center;
			div {
				@include size(80px, 80px);
				margin: 10px;
			}
			span {
				width: calc(100% - 100px);
				margin: 10px;
			}
		}
		@media (max-width: $screen-xs-max) {
			display: none;
		}
	}
}

.zoomWindowContainer .zoomWindow {
	left: 500px;
}

.h1_container {
	padding: 0 15px;
	&:before {
		content: '';
		display: inline-block;
		height: 2px;
		width: 30px;
		background: $tc2;
		position: relative;
		margin: 42px 10px 10px 0;
		float: left;
	}
	h1 {
		margin: 0 auto;
		text-transform: uppercase;
		padding: 25px 0 10px 0;
		font-size: 30px;
		display: inline-block;
		width: calc(100% - 40px);
	}
}

.horital {
	position: relative;
}

.owl-carousel {
	.owl-wrapper-outer {
		border: none;
		@include box-shadow(none);
		@include rounded-corners(0);
	}
}

.related {
	.owl-item {
		.product-col {
			border-left: 1px solid $border-color;
		}
		&:first-child {
			.product-col {
				border-left: 1px solid transparent;
			}
		}
	}
}

#input-captcha, #input-payment-captcha {
	margin-bottom: 10px;
}
